import React, { useState } from 'react';

export default function WarrantySection() {
    const [activeTab, setActiveTab] = useState(null);

    const openTab = (tabName) => {
        setActiveTab(tabName === activeTab ? null : tabName);
    };

    return (
        <div className="Service-4">
            <p className='Service-alert'>Товари, куплені у нашому магазині, не підлягають поверненню згідно зі статтею 8, частиною 2 Закону України "Про захист прав споживачів". Проте, виходячи із нашого прагнення задовольнити потреби клієнтів та дбати про нашу репутацію,
            у випадку, якщо придбаний в нас товар виявиться непрацездатним протягом гарантійного періоду, ми готові провести його ремонт або обмінати на аналогічний товар.</p>
            <div className="Service-4-1">
                <div className={`tab ${activeTab === 'terms' ? 'active' : ''}`} onClick={() => openTab('terms')}>Умови гарантії</div>
                <div className={`tab ${activeTab === 'coverage' ? 'active' : ''}`} onClick={() => openTab('coverage')}>Охоплення гарантії</div>
                <div className={`tab ${activeTab === 'process' ? 'active' : ''}`} onClick={() => openTab('process')}>Процес звернення</div>
            </div>
            <div className={`tab-content ${activeTab === 'terms' ? 'show' : ''}`} id="terms">
                <h2 className='titles'>Умови гарантії</h2>
                <p>На товари, що придбані в нашому магазині, надається гарантія на 3 місяця, що підтверджує їхню справність і готовність до експлуатації. Усі технічні пристрої, доступні в нашому асортименті, проходять комплексну перевірку та тестування на трьох етапах:
                1.Кожна одиниця перевіряється на справність нашими постачальниками за кордоном для підтвердження якості; 2.Кожна одиниця перевіряється на справність в нашому сервісному центрі;
                3.Кожна одиниця виставлена в продажу проходить тестування кожний місяць. Ваш термін гарантії вказаний в гарантійному талоні! Будь ласка, перевіряйте комплектність і відсутність дефектів у товарі при отриманні!
                </p>
            </div>
            <div className={`tab-content ${activeTab === 'coverage' ? 'show' : ''}`} id="coverage">
                <h2 className='titles'>Охоплення гарантії</h2>
                <p>Гарантія надається на всі товари, що придбані в нашому магазині, і охоплює ремонт та заміну несправних деталей. А у разі критичних поломок, коли товар не підлягає ремонту, ми здійснимо БЕЗКОШТОВНУ заміну даного товару на аналогічний або схожий за ціною та характеристиками. Гарантійний термін не продовжується на новий період з моменту здійснення гарантійного ремонту!
                <strong className="Service-alert"> Гарантія нашого товару не розповсюджується на випадки механічних пошкоджень від покупця, порушення правил використання, несанкціонованого ремонту чи змін у внутрішній структурі товару, на проблеми спричинені: стихійним лихом, втратою напруги в електромережі, проблемами з водопостачанням, і дефектами інших систем!</strong> 
                </p>
            </div>
            <div className={`tab-content ${activeTab === 'process' ? 'show' : ''}`} id="process">
                <h2 className='titles'>Процес звернення</h2>
                <p>Телефонуйте на нище вказаний номер телефону сервісного центру, при будь-яких несправностях протягом гарантійного періоду. Якщо придбаний в нашому магазині товар знаходиться в Києві, наші фахівці приїдуть до Вас на адресу і усунуть проблему на місці. Якщо придбаний товар знаходиться в інших населених пунктах Київської області його потрібно привезти до нас на ремонт в сервісний центр. Якщо придбаний товар знаходиться в інших населених пунктах України, зв'яжіться з менеджером для уточнення подальших дій!
                </p>
            </div>
            <strong class="Service-alert">Сервісний центр: 0984279677</strong>
        </div>
    );
}


