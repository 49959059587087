const items = [
    {
        id: 2.1,
        title: 'LG',
        img: '/img/fridge/1.jpg',
        desc: 'Модель: GBB70PZEFS, Габарити: 198*60*64см, Система: No frost, Мультіфреш зона: двійна, Двигун: Лінійний інвертор, Енергозбереження: А+++, LED: Так',
        category: 'fridge',
        price: '15500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.2,
        title: 'Haier',
        img: '/img/fridge/2.jpg',
        desc: 'Модель: A3FE742CMJ, Габарити: 190*70*63см, Система: No frost, Мультіфреш зона: Двійна, Двигун: Інвертор, Енергозбреження: А+++, Управління: Сенсорне і WiFi,  LED: Так',
        category: 'fridge',
        price: '33500',
        hot: true,
        url: './Haier2206',
      },
      {
        id: 2.3,
        title: 'Whirlpool',
        img: '/img/fridge/3.jpg',
        desc: 'Модель: W79210KAQUA, Габарити: 201*60*63см, Система:No frost, Двигун: Інвентор, Енергозбереження: А+++, Мультіфреш зона: Двійна, Диспенсер: Так, Управління: Сенсорне, Країна: Італія, LED: Так',
        category: 'fridge',
        price: '18200',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.4,
        title: 'Bauknecht ',
        img: '/img/fridge/4.jpg',
        desc: 'Габарити: 190*60* 60см, Система: Крапельна, Енергозбереження: А+++, Мультіфреш зона: Так, Двигун: Інвертор, Управління: Дисплей, LED: Так',
        category: 'fridge',
        price: '14500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.5,
        title: 'Liebherr',
        img: '/img/fridge/5.jpg',
        desc: 'ПІД ЗАБУДОВУ, Габарити: 176*55*56см, Система: No frost, Покриття: антибактеріальне, Двигун: Інвертор, Енергозбереження: А+++, Управління: Сенсорне, Android, Країна: Німеччина, LED: Так',
        category: 'fridge',
        price: '25999',
        hot: true,
        url: './NoInfo',
      },
      {
        id: 2.6,
        title: 'Amica',
        img: '/img/fridge/6.jpg',
        desc: 'Габарити: 185*60*63см, Система: No frost, Енергозбереження: А+++, Двигун: Інвертор, Матеріал: Скло, Країна: Польща, Led: Так',
        category: 'fridge',
        price: '13999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.7,
        title: 'Amica',
        img: '/img/fridge/7.jpg',
        desc: 'Габарити: 145*55*56см, Система: Крапельна, Енергозбереження: А++, Країна: Польща',
        category: 'fridge',
        price: '12800',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.8,
        title: 'Bosch',
        img: '/img/fridge/8.jpg',
        desc: 'Модель: KGV36VL30/02, Габарити: 185*60*62см, Енергозбереження: А++, Двинун: Інвертор, Система: Крапельна, Країна: Німеччина',
        category: 'fridge',
        price: '12500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.9,
        title: 'Miele',
        img: '/img/fridge/9.jpg',
        desc: 'Модель: KFN8763, Габарити: 201*60*65, Система: Статика&No Frost, Управління: Електронне, Матеріал: Нержавійка, LED: Так',
        category: 'fridge',
        price: '14500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.10,
        title: 'Beko',
        img: '/img/fridge/10.jpg',
        desc: 'Габарити: 190*70*64см, Модель: K70475NE, Система: No frost, Країна: Туреччина, Двигун: Інвентор, Льодогенератор: Так, Мультіфреш зона: Так, Матеріал: Нержавійка, LED: Так',
        category: 'fridge',
        price: '17500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.11,
        title: 'Liebherr',
        img: '/img/fridge/11.jpg',
        desc: 'Модель: CNPesf3913 index21A, Габарити: 201*60*64см, Система: Статика&No frost, Країна: Німеччина, Енергозбереження: А+++, Двигун: Інвентор, Мультіфреш зона: Так, LED: Так',
        category: 'fridge',
        price: '18999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.12,
        title: 'Мiele',
        img: '/img/fridge/12.jpg',
        desc: 'Габарити: error, Модель: KD 26022 ws, Система: No frost, Країна: Німеччина, Управління: Android, Двигун: Інвентор',
        category: 'fridge',
        price: '17999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.13,
        title: 'Liebherr',
        img: '/img/fridge/13.jpg',
        desc: 'Габарити: 160*55*60см, Система: Статика&No Frost, Двигун: Інвентор, Енергозбереження: А+++, Країна: Німеччина, LED: Так',
        category: 'fridge',
        price: '13200',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.14,
        title: 'Gorenje',
        img: '/img/fridge/14.jpg',
        desc: 'Габарити: 185*60*64см, Система: Статика, Енергозбереження: А+++, Двигун: Інвертор, Країна: Словаччина, Управління: Електронне',
        category: 'fridge',
        price: '16500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.15,
        title: 'Blomberg',
        img: '/img/fridge/15.jpg',
        desc: 'Габарити: 185*60*62см, Система: No Frost, Країна: Німеччина, Автозаморозка: Так, Матеріал: Нержавійка',
        category: 'fridge',
        price: '9500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.16,
        title: 'Liebherr',
        img: '/img/fridge/16.jpg',
        desc: 'ПІД ЗАБУДОВУ, Габарити: 176*55*56см, Система: Статика&No Frost, Двигун: Інвентор, Енергозбереження: А+++, LED: Так',
        category: 'fridge',
        price: '14500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.17,
        title: 'Privileg',
        img: '/img/fridge/17.jpg',
        desc: 'Габарити: 180*60*60см, Система: Крапелька, Енергозбереження: А+++, Двигун: Інвертор, Країна: Німеччина, Led: Так',
        category: 'fridge',
        price: '12200',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.18,
        title: 'Liebherr',
        img: '/img/fridge/18.jpg',
        desc: 'Габарити: 201*60*63см, Система: Статика&No Frost, Енергозбереження: А+++, Двигун: Інвентор, Країна: Німеччина, LED: Так',
        category: 'fridge',
        price: '14900',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.19,
        title: 'Liebherr',
        img: '/img/fridge/19.jpg',
        desc: 'ПІД ЗАБУДОВУ, Модель: IKP 2354 Index20/001, Габарити: 120*55*56см, Система: Статика, Енергозбереження: А+++, Двигун: Інвентор, Країна: Німеччина, Управління: Сенсор, LED: Так',
        category: 'fridge',
        price: '9999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.20,
        title: 'Liebherr',
        img: '/img/fridge/20.jpg',
        desc: 'ПІД ЗАБУДОВУ, Габарити: 120*55*56см, Система: Вентиляційна статика, Енергозбереження: А++, Двигун: Інвентор, Країна: Німеччина, Біофреш зона: Так, LED: Так',
        category: 'fridge',
        price: '8999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.21,
        title: 'Liebherr',
        img: '/img/fridge/21.jpg',
        desc: 'Габарити: 201*60*64, Модель: CNPel 4813, Система: No frost&Статика, Двигун: Інвертор, Енергозбереження: А+++, Матеріал: Нержавійка, Країна: Німеччина, Стабілізатор: Так, LED: Так',
        category: 'fridge',
        price: '20500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.22,
        title: 'Bosch',
        img: '/img/fridge/22.jpg',
        desc: 'Габарити: 201*60*63см, Система: Крапельна, Матеріал: Нержавійка, Енергозбереження: А++, Двигун: Інвертор, Країна: Німеччина, LED: Так',
        category: 'fridge',
        price: '14500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.23,
        title: 'Hanseatic',
        img: '/img/fridge/23.jpg',
        desc: 'Габарити: 185*60*62см, Система: Крапельна, Країна: Німеччина',
        category: 'fridge',
        price: '11999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.24,
        title: 'SMEG',
        img: '/img/fridge/24.jpg',
        desc: 'Габарити: 200*70*70см, Система: No frost, Двигун: Інвертор, Енергозбереження: А+++ -35%, Управління: Сенсорне, Мультіфреш зона: Двійна, Виробник: США&Італія, Льодогенератор: Так, LED: Так, Еко-режим: Так, Матеріал: Фарбований метал, Ручки: Латунь',
        category: 'fridge',
        price: '73500',
        hot: true,
        url: './NoInfo',
      },
      {
        id: 2.25,
        title: 'Gorenje',
        img: '/img/fridge/25.jpg',
        desc: 'Габарити: 185*60*62см, БЕЗ МОРОЗИЛКИ, Система: Статична, Енергозбереження: А++, Країна: Словенія, Мультіфреш зона: Так',
        category: 'fridge',
        price: '14500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.26,
        title: 'AEG',
        img: '/img/fridge/26.jpg',
        desc: 'Габарити: 176*55*56см, ВБУДОВАНИЙ, Система: No frost, Країна: Італія, Нульова зона: Так',
        category: 'fridge',
        price: '13000',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.27,
        title: 'AEG',
        img: '/img/fridge/27.jpg',
        desc: 'Модель: KGN34VL3A, Габарити: 185*60*63см, Система: No frost, Двигун: Інвертор, Енергозбереження: А+++, Фреш зона: Подвійна, Країна: Німеччина, Покриття: Нержавійка, LED: Так',
        category: 'fridge',
        price: '15500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.28,
        title: 'Bosh',
        img: '/img/fridge/28.jpg',
        desc: 'Габарити: 190*70*74см, Модель: KGE58BI40/02, Система: Вентиляційна статика, Двигун: Інверторний, Країна: Німеччина, Мультіфреш зона: Так, Матеріал: Нержавійка',
        category: 'fridge',
        price: '19500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.29,
        title: 'AEG',
        img: '/img/fridge/29.jpg',
        desc: 'Модель: RKB736E5MB, Габарити: 185*60*62см, Система: No frost, Двигун: Інвертор, Енергозбереження: А+++, Мультфреш зона: Потрійна, Країна: Німеччина, LED: Так, Рік: 2024',
        category: 'fridge',
        price: '23500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.30,
        title: 'Beko',
        img: '/img/fridge/30.jpg',
        desc: 'Габарити: 176*54*54см, ВБУДОВАНИЙ, Система: Крапельна, Країна: Туречинна',
        category: 'fridge',
        price: '12000',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.31,
        title: 'Bosch',
        img: '/img/fridge/31.jpg',
        desc: 'Габарити: 185*60*63см, Модель: KGN34VL3A, Система: No frost, Двигун: Інвертор, Енергозбереження: А+++, Країна: Німеччина, Мультіфреш зона: Двійна, LED: Так',
        category: 'fridge',
        price: '15500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.32,
        title: 'Liebherr',
        img: '/img/fridge/32.jpg',
        desc: 'Габарити: 201*60*63см, Модель: CBNef4815, Система: No frost, Енергозбереження: А+++, Двигун: Інвенторний, Країна: Німеччина, Забезпечення: Wi-fi Android, Покриття: Антибактеріальне, Мультіфреш зона: Двійна, Матеріал: Нержавійка, LED: Так',
        category: 'fridge',
        price: '31500',
        hot: true,
        url: './NoInfo',
      },
      {
        id: 2.33,
        title: 'Liebherr',
        img: '/img/fridge/33.jpg',
        desc: 'Габарити: 201*60*65см, Модель: CNPes 4868 Index 20B/001, Система: No frost, Країна: Німеччина, Забезпечення: Wi-fi Android, Двері: Реверсивні, Система: Super cool, Мультіфреш зона: Так, Покриття: Антибактеріальне, LED: Так',
        category: 'fridge',
        price: '29900',
        hot: true,
        url: './Liebherr1863',
      },
      {
        id: 2.34,
        title: 'Miele',
        img: '/img/fridge/34.jpg',
        desc: 'Модель: KD12823 S-1, Габарити: 185*60*62см, Система: Крапельна&No frost, Енергозбереження: А++, Країна: Німеччина',
        category: 'fridge',
        price: '13000',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.35,
        title: 'Gorenje',
        img: '/img/fridge/35.jpg',
        desc: 'Габарити: 185*60*60см, Система: Крапельна&No frost, Країна: Словенія',
        category: 'fridge',
        price: '13000',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.36,
        title: 'Exquisit',
        img: '/img/fridge/36.jpg',
        desc: 'Габарити: 155*55*56см, Система: Статика, Енергозбереження: А++, Фасад: Скло, Країна: Німеччина',
        category: 'fridge',
        price: '9500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.37,
        title: 'Indesit',
        img: '/img/fridge/37.jpg',
        desc: 'Габарити: 165*60*63см, Система: Крапельна, Енергозбреження: А++, Двигун: Інвертор, Країна: Італія, LED: Так',
        category: 'fridge',
        price: '9500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.38,
        title: 'Gorenje',
        img: '/img/fridge/38.jpg',
        desc: 'Модель: NK8990DC, Габарити: 201*60*64см, Система: No Frost, Двигун: Інвертор, Енергозбереження: А+++ -35%, Мультіфреш зона: Подвійна, Країна: Словаччина, LED: Так',
        category: 'fridge',
        price: '16999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.39,
        title: 'Liebherr',
        img: '/img/fridge/39.jpg',
        desc: 'Габарити: 160*55*60см, Система: Статика&No frost, Країна: Німеччина, Двигун: Інвенторний, Енергозбереження: А+++',
        category: 'fridge',
        price: '13500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.40,
        title: 'Liebherr',
        img: '/img/fridge/40.jpg',
        desc: 'Габарити: 201*60*65см, Модель: CNP3913, Система: Статика&No frost, Двигун: Інвенторний, Енергозбереження: А+++, Управління: Електронне, Країна: Німеччина, LED: Так',
        category: 'fridge',
        price: '19000',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.41,
        title: 'Gorenje',
        img: '/img/fridge/41.jpg',
        desc: 'Габарити: 185*60*56см, Система: Венталяційна статика, Країна: Словенія, Мультіфреш зона: Так, LED: Так',
        category: 'fridge',
        price: '12500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.42,
        title: 'Siemens',
        img: '/img/fridge/42.jpg',
        desc: 'Габарити: 200*60*61см, Модель: KG39EA140, Система: Венталяційна статика, Країна: Туреччина, Мультіфреш зона: Так',
        category: 'fridge',
        price: '14500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.43,
        title: 'Severin',
        img: '/img/fridge/43.jpg',
        desc: 'Габарити: 147*54*54см, Модель: KS9995, Система: Венталяційна статика, Країна: Німеччина',
        category: 'fridge',
        price: '10500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.44,
        title: 'Bosch',
        img: '/img/fridge/44.jpg',
        desc: 'Габарити: 200*70*64см, Система: No Frost, Двигун: Інвентор, Енергозбереження: А+++, Мультіфреш зона: Двійна, LED: Так',
        category: 'fridge',
        price: '22500',
        hot: true,
        url: './NoInfo',
      },
      {
        id: 2.45,
        title: 'Grundig',
        img: '/img/fridge/45.jpg',
        desc: 'Габарити: 201*60*64см, Модель: K60406NE, Система: No Frost, Двигун: Інвентор, Енергозбереження: А+++, Країна: Німеччина, Покриття: Анбикатеріальне, Матеріал: Нержавійка, Мультіфреш зона: Двійна, LED: Так',
        category: 'fridge',
        price: '17999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.46,
        title: 'Liebherrr',
        img: '/img/fridge/46.jpg',
        desc: 'Габарити: 180*60*61см, Система: Статика&No frost, Країна: Німеччина, Led: Так',
        category: 'fridge',
        price: '14000',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.47,
        title: 'Ikea',
        img: '/img/fridge/47.jpg',
        desc: 'ПІД ЗАБУДОВУ, Габарити: 158*53*55см, Система: Крапельна, Енергозбереження: А+, Країна: Швеція, LED: Так',
        category: 'fridge',
        price: '11900',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.48,
        title: 'SchaubLorenz',
        img: '/img/fridge/48.jpg',
        desc: 'Габарити: 185*60*62см, Система: Статика, Енергозбереження: А++, Країна: Німеччина, Led: Так',
        category: 'fridge',
        price: '11999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.49,
        title: 'Liebherr',
        img: '/img/fridge/49.jpg',
        desc: 'Модель: KBes 4260 Index22, Габарити: 190*60*64см, Система: Статика&No frost, Двигун: Інвентор, Енергозбереження: А+++, Зона свіжості: Трійна, Країна: Німеччина, Led: Так',
        category: 'fridge',
        price: '13499',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.50,
        title: 'Bosch',
        img: '/img/fridge/50.jpg',
        desc: 'Модель: KGN84E90/01, Габарити: 201*60*63см, Система: No frost, Двигун: Інвентор, Енергозбереження: А++, Країна: Німеччина, Матеріал: Харчова нержавійка, Led: Так',
        category: 'fridge',
        price: '15900',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.51,
        title: 'Bosch',
        img: '/img/fridge/51.jpg',
        desc: 'Модель: KDN36A43/05, Габарити: 170*70*60см, Система: No frost, Двигун: Інвентор, Енергозбереження: А++, Країна: Німеччина, Led: Так',
        category: 'fridge',
        price: '12500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.52,
        title: 'Hanseatic',
        img: '/img/fridge/52.jpg',
        desc: 'Габарити: 143*50*55см, Система: Статика, Енергозбереження: А++, Країна: Німеччина',
        category: 'fridge',
        price: '8999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 2.53,
        title: 'Ikea',
        img: '/img/fridge/53.jpg',
        desc: 'ПІД ЗАБУДОВУ, Габарити: 156*55*56см, Система: Крапельна, Енергозбереження: А++, Управління: Механічне, Країна: Швеція, Led: Так',
        category: 'fridge',
        price: '12500',
        hot: false,
        url: './NoInfo',
      },

      {
        id: 3.1,
        title: 'Ikea',
        img: '/img/sidebyside/1.jpg',
        desc: 'Габарити: 176*54*54см, Система: Статика&No Frost, Двигун: Інвентор, Енергозбереження: А++, Управління: Сенсорне, LED: Так',
        category: 'sidebyside',
        price: '39900',
        hot: true,
        url: './NoInfo',
      },
      {
        id: 3.2,
        title: 'Amica',
        img: '/img/sidebyside/2.jpg',
        desc: 'Модель: KGCN 388 070 E, Габарити: 185*70*67см, Система: No Frost, Двигун: Інвентор, Енергозбереження: А+++, Покриття: Антибактеріальне, Зона свіжості: Так, Країна: Польща, LED: Так',
        category: 'sidebyside',
        price: '25999',
        hot: true,
        url: './Amica2208',
      },
      {
        id: 3.3,
        title: 'Medion',
        img: '/img/sidebyside/3.jpg',
        desc: 'Габарити: 185*90*68см, Система: No Frost, Двигун: Інвентор, Енергозбереження: А+++, Управління: Сенсорне, Покриття: Антибактуріальне, Країна: Німеччина, LED: Так',
        category: 'sidebyside',
        price: '24900',
        hot: true,
        url: './NoInfo',
      },
      {
        id: 3.4,
        title: 'Samsung',
        img: '/img/sidebyside/4.jpg',
        desc: 'Габарити: 180*90*70см, Система: No Frost, Двигун: Інвентор, Мультіфреш зона: Так, Льодогенератор: Так, Диспенсер: Так, LED: Так',
        category: 'sidebyside',
        price: '19900',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 3.5,
        title: 'Samsung',
        img: '/img/sidebyside/5.jpg',
        desc: 'Габарити: 180*90*70см, Модель: RSA1UTNE, Система: No Frost, Двигун: Інвентор, Льодогенератор: Так, Диспенсер: Так, LED: Так',
        category: 'sidebyside',
        price: '21000',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 3.6,
        title: 'Whirlpool',
        img: '/img/sidebyside/6.jpg',
        desc: 'Габарити: 180*90*70см, ВМОНТОВАНА КАВОВАРКА, Система: No Frost, Двигун: Інвентор, Льодогенератор: Так, Диспенсер: Так, LED: Так',
        category: 'sidebyside',
        price: '14999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 3.7,
        title: 'Samsung',
        img: '/img/sidebyside/7.jpg',
        desc: 'Габарити: 180*90*70см, Система: No Frost, Двигун: Інвентор, Мультіфреш зона: Так, Льодогенератор: Так, Диспенсер: Так, LED: Так',
        category: 'sidebyside',
        price: '21900',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 3.8,
        title: 'Hisense',
        img: '/img/sidebyside/8.jpg',
        desc: 'Габарити: 180*90*67см, Система: No Frost, Двигун: Інвентор, Мультіфреш зона: Так, LED: Так',
        category: 'sidebyside',
        price: '17500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 3.9,
        title: 'Samsung',
        img: '/img/sidebyside/9.jpg',
        desc: 'Габарити: 180*90*68см, Модель: RS20VQPS, Система: No Frost, Мінібар зона: Так, Двигун: Інвентор, Мультіфреш зона: Так, LED: Так',
        category: 'sidebyside',
        price: '19900',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 3.10,
        title: 'DAEWOO',
        img: '/img/sidebyside/10.jpg',
        desc: 'Габарити: 180*90*70см, Система: No Frost, Країна: Корея, Двигун: Інвентор, Управління: Екран, Льодогенератор: Так, Диспенсер: Так, Нульова зона: Так, LED: Так',
        category: 'sidebyside',
        price: '21800',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 3.11,
        title: 'Hisense',
        img: '/img/sidebyside/11.jpg',
        desc: 'Модель: RS694N4TC1, Габарити: 180*90*70см, Система: No Frost, Двигун: Інвентор, Енергозбереження: А+++, Покриття: Антибактеріальне, Управління: Екран, Льодогенератор: Так, Диспенсер: Так, Мультіфреш зона: Так, LED: Так',
        category: 'sidebyside',
        price: '25500',
        hot: true,
        url: './NoInfo',
      },
      {
        id: 3.12,
        title: 'Hitachi',
        img: '/img/sidebyside/12.jpg',
        desc: 'Габарити: 180*85*67см, Система: No Frost, Енергозбереження: А++, Управління: Електронне, Диспенсер: Так, Країна: Італія, LED: Так',
        category: 'sidebyside',
        price: '9999',
        hot: true,
        url: './NoInfo',
      },
      {
        id: 3.13,
        title: 'AEG',
        img: '/img/sidebyside/13.jpg',
        desc: 'Модель: RMB86321NX, Габарити: 180*90*75см, Система: No Frost, Двигун: Інвентор, Енергозбереження: А+++, Покриття: Антибактеріальне, Матеріал: Нержавійка, Управління: Електронне, Льодогенератор: Так, Диспенсер: Так, Мультіфреш зона: Так, LED: Так',
        category: 'sidebyside',
        price: '29900',
        hot: true,
        url: './NoInfo',
      },
      
      {
        id: 4.1,
        title: 'Liebherr',
        img: '/img/mini/1.jpg',
        desc: 'Габарити: 87*59*55см, Система: No Frost, Країна: Німеччина, Забезпечення: Android',
        category: 'mini',
        price: '13900',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 4.2,
        title: 'Severin',
        img: '/img/mini/2.jpg',
        desc: 'Габарити: 85*55*57см, БЕЗ МОРОЗИЛКИ, Система: Крапельна, Країна: Німеччина',
        category: 'mini',
        price: '6500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 4.3,
        title: 'Exqusit',
        img: '/img/mini/3.jpg',
        desc: 'Габарити: 50*47*44см, Система: Венталяційна статика, Країна: Німеччина',
        category: 'mini',
        price: '3999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 4.4,
        title: 'Comfee',
        img: '/img/mini/4.jpg',
        desc: 'Габарити: 85*55*55см, БЕЗ МОРОЗИЛКИ',
        category: 'mini',
        price: '6500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 4.5,
        title: 'Liebherr',
        img: '/img/mini/5.jpg',
        desc: 'Габарити: 85*55*57см, БЕЗ МОРОЗИЛКИ, Країна: Німеччина',
        category: 'mini',
        price: '7000',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 4.6,
        title: 'Whirlpool',
        img: '/img/mini/6.jpg',
        desc: 'Габарити: 85*55*56см, БЕЗ МОРОЗИЛКИ,Система: Крапельна, Країна: Німеччина',
        category: 'mini',
        price: '6100',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 4.7,
        title: 'Miele',
        img: '/img/mini/7.jpg',
        desc: 'Габарити: 87*53*55см, Система: Вентиляційна статика, БЕЗ МОРОЗИЛКИ, Країна: Німеччина',
        category: 'mini',
        price: '6500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 4.8,
        title: 'PKM',
        img: '/img/mini/8.jpg',
        desc: 'Габарити: 85*55*55см, Модель: KSR86.4, Система: Крапельна, Країна: Німеччина',
        category: 'mini',
        price: '6999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 4.9,
        title: 'Haier',
        img: '/img/mini/9.jpg',
        desc: 'Габарити: 85*55*56см, Система: Крапельна',
        category: 'mini',
        price: '5800',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 4.10,
        title: 'Liebherr',
        img: '/img/mini/10.jpg',
        desc: 'Габарити: 88*59*56см, ВБУДОВАНИЙ, З МОРОЗИЛКОЮ Система: No frost, Енергозбереження: А+++, Країна: Німеччина',
        category: 'mini',
        price: '9500',
        hot: false,
        url: './NoInfo',
      },

      {
        id: 5.1,
        title: 'Exquisit',
        img: '/img/freezer/1.jpg',
        desc: 'Габарити: 85*55*56см, Ящики: 4, Система: No frost, Країна: Німеччина',
        category: 'freezer',
        price: '5999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 5.2,
        title: 'Miele',
        img: '/img/freezer/2.jpg',
        desc: 'Габарити: 85*60*60см, Ящики: 4, Система: No frost, Країна: Німеччина',
        category: 'freezer',
        price: '8700',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 5.3,
        title: 'Liebherr Premium',
        img: '/img/freezer/3.jpg',
        desc: 'Габарити: 165*65*65см, Ящики: 6, Система: No frost, Енергозбереження: А++, Країна: Німеччина, LED: Так',
        category: 'freezer',
        price: '13999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 5.4,
        title: 'Liebherr',
        img: '/img/freezer/4.jpg',
        desc: 'Габарити: 85*55*56см, Ящики: 4, Система: No frost, Країна: Німеччина',
        category: 'freezer',
        price: '7700',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 5.5,
        title: 'Liebherr Premium',
        img: '/img/freezer/5.jpg',
        desc: 'Модель: CNP 3666 Index20Q/001, Габарити: 177*72*75см, Ящики: 7шт, Система: No frost, Суперзаморозка: Так, Енергозбереження: А+++, Країна: Німеччина, LED: Так',
        category: 'freezer',
        price: '16500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 5.6,
        title: 'Liebherr',
        img: '/img/freezer/6.jpg',
        desc: 'Габарити: 177*72*75см, Модель: CNP 3666 Index20Q/001, Ящики: 7, Двигун: Інвенторний, Країна: Німеччина',
        category: 'freezer',
        price: '18500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 5.7,
        title: 'Liebherr',
        img: '/img/freezer/7.jpg',
        desc: 'Габарити: 88*56*53см, Ящики: 4, Система: No frost, Двигун6 Інвенторний, Забезпечення: Wi-fi Android, Країна: Німеччина',
        category: 'freezer',
        price: '19900',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 5.8,
        title: 'Premiere',
        img: '/img/freezer/8.jpg',
        desc: 'Габарити: 143*54*56см, Ящики: 6, Система: Крапельна, Країна: Німеччина',
        category: 'freezer',
        price: '9300',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 5.9,
        title: 'Silver Crest',
        img: '/img/freezer/9.jpg',
        desc: 'Габарити: 88*56*53см, Ящики: 4, Країна: Німеччина',
        category: 'freezer',
        price: '5999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 5.10,
        title: 'Liebherr',
        img: '/img/freezer/10.jpg',
        desc: 'Модель: GNP 3755 Index 20A/001, Габарити: 164*60*65см, Ящики: 7, Система: No frost, Енергозбереження: А+++, Країна: Німеччина, Забезпечення: Android, LED: Так',
        category: 'freezer',
        price: '22500',
        hot: true,
        url: './NoInfo',
      },
      {
        id: 5.11,
        title: 'Bauknecht',
        img: '/img/freezer/11.jpg',
        desc: 'Габарити: 178*70*68см, Модель: GKN 2173 A3+, Ящики: 7, Двигун: Інвентор, Система: No frost, Енергозбереження: А+++',
        category: 'freezer',
        price: '15999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 5.12,
        title: 'Bauknecht',
        img: '/img/freezer/12.jpg',
        desc: 'Габарити: 140*58*62см, Система: No Frost, Двигун: Інвертор, Ящики: 4 + 1, Країна: Італія, LED: Так',
        category: 'freezer',
        price: '10500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 5.13,
        title: 'Miele',
        img: '/img/freezer/13.jpg',
        desc: 'Габарити: 85*60*63см, Система: No frost, Двигун: Інвентор, Енергозбереження: А++, Країна: Німеччина',
        category: 'freezer',
        price: '9800',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 5.14,
        title: 'Bosch',
        img: '/img/freezer/14.jpg',
        desc: 'Габарити: 155*70*67см, Система: No frost, Двигун: Інвентор, Енергозбереження: А++, Ящиків: 6, Країна: Німеччина, LED: Так',
        category: 'freezer',
        price: '14500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 5.15,
        title: 'Privileg',
        img: '/img/freezer/15.jpg',
        desc: 'Габарити: 85*60*60м, Система: Крапельна, Ящиків: 3, Країна: Німеччина',
        category: 'freezer',
        price: '6800',
        hot: false,
        url: './NoInfo',
      },
      
      {
        id: 6.1,
        title: 'Bosch Maxx5',
        img: '/img/washer/1.jpg',
        desc: 'Габарити: 85*60*45см, Барабан: 6кг, Швидкість обертання: 1000об/хв, Управління: механічне, Енергозбереження: А+++',
        category: 'washer',
        price: '9900',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.2,
        title: 'Siemens IQ700',
        img: '/img/washer/2.jpg',
        desc: 'Габарити: 85*60*60см, Модель: WM14W5FCB/01, Барабан: 9кг, Швидкість обертання: 1600об/хв, Двигун: Інвенторний, Країна: Німеччина, А+++',
        category: 'washer',
        price: '14500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.3,
        title: 'Bosch',
        img: '/img/washer/3.jpg',
        desc: 'Модель: WAW28550, Габарити: 85*60*60см, Загрузка: 8кг, Швидкість обертання: 1400об/хв, Двигун: Інвенторний, Країна: Німеччина, А+++',
        category: 'washer',
        price: '15800',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.4,
        title: 'Bosch Maxx 7',
        img: '/img/washer/4.jpg',
        desc: 'Габарити: 85*60*60см, Загрузка: 7кг, Швидкість обертання: 1000об/хв, Аквастоп: Так, А+++',
        category: 'washer',
        price: '10500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.5,
        title: 'Bosch Logixx8',
        img: '/img/washer/5.jpg',
        desc: 'Габарити: 85*60*60см, Модель: L6FB50489, Загрузка: 8кг, Швидкість обертання: 1400об/хв, Двигун: Інвенторний, Аквастоп: Так, А++',
        category: 'washer',
        price: '11800',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.6,
        title: 'Samsung ecobubble',
        img: '/img/washer/6.jpg',
        desc: 'Модель: WW91K6604QW, Габарити: 85*60*60см, Загрузка: 9кг, Швидкість обертання: 1400об/хв, Країна: Корея, Двигун: Інвертор, Енергозбереження: А+++, Клас прання: А, Управління: Електронне',
        category: 'washer',
        price: '12900',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.7,
        title: 'AEG 9000 Series',
        img: '/img/washer/7.jpg',
        desc: 'Габарити: 85*60*60см, Модель: L7FB78490, Загрузка: 9кг, Швидкість обертання: 1600об/хв, Двигун: Інвенторний, Автоваги: Так, Автодоз: Так, А+++',
        category: 'washer',
        price: '15999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.8,
        title: 'Bosch',
        img: '/img/washer/8.jpg',
        desc: ' ПІД ЗАБУДОВУ, Габарити: 81*60*55см, Загрузка: 7кг, Швидкість обертання: 1200об/хв, Країна: Німеччина, Енергозбереження: А+++',
        category: 'washer',
        price: '8500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.9,
        title: 'Bosch',
        img: '/img/washer/9.jpg',
        desc: 'Габарити: 85*60*60см, Модель: WAN2826EPL/05, Загрузка: 8кг, Швидкість обертання: 1400об/хв, Аквастоп: Так, Двигун: Інвенторний, A+++',
        category: 'washer',
        price: '12500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.10,
        title: 'Siemens iq500',
        img: '/img/washer/10.jpg',
        desc: 'Габарити: 85*60*60см, Загрузка: 8кг, Швидкість обертання: 1400об/хв, Аквастоп: Так, Двигун: Інвенторний, Енергозбереження: A+++',
        category: 'washer',
        price: '12900',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.11,
        title: 'Samsung',
        img: '/img/washer/11.jpg',
        desc: 'Габарити: 85*60*60см, Модель: WW80K5400UW/EG, Загрузка: 8кг, Швидкість обертання: 1400об/хв, Двигун: Інвенторний, A+++',
        category: 'washer',
        price: '11300',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.12,
        title: 'AEG',
        img: '/img/washer/12.jpg',
        desc: 'Габарити: 85*60*60см, Модель: L7FB78490, Загрузка: 9кг, Швидкість обертання: 1600об/хв, Країна: Італія, Двигун: Інвенторний, Автодос: Так, A+++',
        category: 'washer',
        price: '21900',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.13,
        title: 'AEG Lavalogic',
        img: '/img/washer/13.jpg',
        desc: 'Габарити: 85*60*60см, Загрузка: 8кг, Швидкість обертання: 1400об/хв, Led:Так',
        category: 'washer',
        price: '7500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.14,
        title: 'Bosch 4 serie',
        img: '/img/washer/14.jpg',
        desc: 'Габарити: 85*60*60см, Модель: WAN282V9/31, Загрузка: 8кг, Швидкість обертання: 1400об/хв, Двигун: Інвертор, Аквастоп: Так, Країна: Німеччина, Енергозбереження: А+++',
        category: 'washer',
        price: '13000',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.15,
        title: 'Hanseatic',
        img: '/img/washer/15.jpg',
        desc: 'Габарити: 85*60*60см, Модель: HWM714 A3d, Загрузка: 7кг, Швидкість обертання: 1400об/хв, Двигун: Інвенторний, A+++',
        category: 'washer',
        price: '12500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.16,
        title: 'Siemens IQ700',
        img: '/img/washer/16.jpg',
        desc: 'Габарити: 85*60*60см, Модель: WM16W540/14, Загрузка: 8кг, Швидкість обертання: 1600об/хв, Країна: Німеччина, Двигун: Інвенторний, A+++',
        category: 'washer',
        price: '13500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.17,
        title: 'AEG Protex',
        img: '/img/washer/17.jpg',
        desc: 'Габарити: 85*60*52см, Модель: FL5543M1, Загрузка: 7кг, Швидкість обертання: 1400об/хв',
        category: 'washer',
        price: '11500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.18,
        title: 'Constructa',
        img: '/img/washer/18.jpg',
        desc: 'Модель: CWF12N25,Габарити: 85*60*60см, Загрузка: 8кг, Швидкість обертання: 1400об/хв, Двигун: Інвенторний, Енергозбереження: A+++, Країна: Німеччина',
        category: 'washer',
        price: '11800',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.19,
        title: 'AEG 7000 series',
        img: '/img/washer/19.jpg',
        desc: 'Габарити: 85*60*60см, Модель: L7FB78490, Загрузка: 1-9кг, Швидкість обертання: 1600об/хв, Управління: Wifi, Двигун: Інвентор, AUTODOSE: Так, Країна: Італія, A+++',
        category: 'washer',
        price: '16000',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.20,
        title: 'LG',
        img: '/img/washer/20.jpg',
        desc: 'Габарити: 84*59*59см, Модель: F14WM7LNO, Загрузка: 7кг, Швидкість обертання: 1400об/хв, Країна: Корея, Двигун: Інвенторний, Енергозбереження: A+++',
        category: 'washer',
        price: '12900',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.21,
        title: 'Samsung',
        img: '/img/washer/21.jpg',
        desc: 'Модель: Ww81T854ABT/s2, Габарити: 85*60*60см, Загрузка: 9кг, Швидкість обертання: 1600об/хв, Двигун: Інвертор, Енергозбереження: А+++, Країна: Корея',
        category: 'washer',
        price: '19500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.22,
        title: 'Samsung',
        img: '/img/washer/22.jpg',
        desc: 'Габарити: 85*60*67см, Модель: WW10H9600EW, Загрузка: 10кг, Швидкість обертання: 1600об/хв, Управління: Wi-fi Android, Двигун: Інвенторний, Автодос: Так, Твіндос: Так, LED: Так, A+++',
        category: 'washer',
        price: '24000',
        hot: true,
        url: './Samsung2023',
      },
      {
        id: 6.23,
        title: 'Bauknecht',
        img: '/img/washer/23.jpg',
        desc: 'Габарити: 85*60*60см, Модель: HD90-A3S979, Загрузка: 9кг, Швидкість обертання: 1600об/хв, Двигун: Інвенторний, Автодоз: Так, LED: Так, A+++',
        category: 'washer',
        price: '19900',
        hot: false,
        url: './Bauknecht1445',
      },
      {
        id: 6.24,
        title: 'Siemens IQ500',
        img: '/img/washer/24.jpg',
        desc: 'Габарити: 85*60*60см, Модель: WM14T5EM, Загрузка: 8кг, Швидкість обертання: 1400об/хв, Двигун: Інвенторний, Автодоз: Так, A+++',
        category: 'washer',
        price: '13000',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.25,
        title: 'Samsung',
        img: '/img/washer/25.jpg',
        desc: 'Габарити: 85*60*60см, Модель: WW80K5400WW/EG, Загрузка: 8кг, Швидкість обертання: 1400об/хв, Двигун: Інвенторний, A+++',
        category: 'washer',
        price: '11900',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.26,
        title: 'Miele W1',
        img: '/img/washer/26.jpg',
        desc: 'Габарити: 85*60*61см, Загрузка: 8кг, Швидкість обертання: 1600об/хв, Країна: Німеччина, Двигун: Інвенторний, Твіндос: Так, A+++',
        category: 'washer',
        price: '20999',
        hot: true,
        url: './Miele1085',
      },
      {
        id: 6.27,
        title: 'Miele W1',
        img: '/img/washer/27.jpg',
        desc: 'Габарити: 85*60*61см, Модель: WkB130WCS, Загрузка: 8кг, Швидкість обертання: 1600об/хв, Країна: Німеччина, Двигун: Інвенторний, LED: Так, A+++',
        category: 'washer',
        price: '19900',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.28,
        title: 'Bosch',
        img: '/img/washer/28.jpg',
        desc: 'Модель: EAY285SL, Габарити: 85*60*60см, Загрузка: 9кг, Швидкість обертання: 1600об/хв, Країна: Німеччина, Енергозбереження: А+++, Двигун: Інвертор, Клас прання: А',
        category: 'washer',
        price: '15500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.29,
        title: 'Siemens',
        img: '/img/washer/29.jpg',
        desc: 'Габарити: 85*60*60см, Загрузка: 8кг, Швидкість обертання: 1400об/хв, Країна: Німеччина, Двигун: Інвертор, Енергозбереження: A+++, Управління: Механічне',
        category: 'washer',
        price: '12900',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.30,
        title: 'Hanseatic',
        img: '/img/washer/30.jpg',
        desc: 'Габарити: 85*60*55см, Загрузка: 8кг, Швидкість обертання: 1400об/хв, Двигун: Інвенторний, A+++',
        category: 'washer',
        price: '14500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.31,
        title: 'AEG oko lavamat',
        img: '/img/washer/31.jpg',
        desc: 'Габарити: 85*60*60см, Модель: L8748efl, Загрузка: 8кг, Швидкість обертання: 1600об/хв',
        category: 'washer',
        price: '11500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.32,
        title: 'Hanseatic',
        img: '/img/washer/32.jpg',
        desc: 'Габарити: 85*60*45см, Модель: HWM6T214D, Загрузка: 6кг, Швидкість обертання: 1600об/хв, Двигун: Інвенторний, A+++',
        category: 'washer',
        price: '14500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.33,
        title: 'Hanseatic',
        img: '/img/washer/33.jpg',
        desc: 'Габарити: 85*60*50см, Модель: HWM6T110D, Загрузка: 6кг, Швидкість обертання: 1400об/хв, Двигун: Інвенторний, A+++',
        category: 'washer',
        price: '13800',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.34,
        title: 'Bosch maxx5',
        img: '/img/washer/34.jpg',
        desc: 'Габарити: 85*60*43см, Загрузка: 5кг, Швидкість обертання: 1000об/хв, Країна: Німеччина',
        category: 'washer',
        price: '7200',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.35,
        title: 'Indesit',
        img: '/img/washer/35.jpg',
        desc: 'Габарити: 85*60*45см, Загрузка: 4кг, Швидкість обертання: 1000об/хв, країна: Італія',
        category: 'washer',
        price: '4800',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.36,
        title: 'Gorenje',
        img: '/img/washer/36.jpg',
        desc: 'Габарити: 85*60*45см, Модель: WS42081, Загрузка: 5кг, Швидкість обертання: 1000об/хв, Енергозбереження: A+, Країна: Словенія',
        category: 'washer',
        price: '6200',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.37,
        title: 'Bosch maxx4',
        img: '/img/washer/37.jpg',
        desc: 'Габарити: 85*60*45см, Загрузка: 4кг, Швидкість обертання: 1000об/хв, Коротке прання: Так, Країна: Німеччина',
        category: 'washer',
        price: '7600',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.38,
        title: 'Hotpoint Ariston',
        img: '/img/washer/38.jpg',
        desc: 'Габарити: 85*60*45см, Загрузка: 5кг, Швидкість обертання: 1000об/хв, країна: Італія',
        category: 'washer',
        price: '7500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.39,
        title: 'Bosch Classixx 6',
        img: '/img/washer/39.jpg',
        desc: 'Габарити: 85*60*50см, Загрузка: 6кг, Швидкість обертання: 800об/хв, Енергозбереження: A+, Країна: Німеччина',
        category: 'washer',
        price: '8500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.40,
        title: 'Bosch logix 8',
        img: '/img/washer/40.jpg',
        desc: 'Габарити: 85*60*60см, Загрузка: 8кг, Швидкість обертання: 1400об/хв, Коротке прання: Так, Аква стоп: Так, Управління: Механічне, Країна: Німеччина',
        category: 'washer',
        price: '9600',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.41,
        title: 'Indesit',
        img: '/img/washer/41.jpg',
        desc: 'Модель: WISN82, Габарити: 85*60*40см, Загрузка: 5кг, Швидкість обертання: 1000об/хв, Країна: Італія',
        category: 'washer',
        price: '5800',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.42,
        title: 'Candy',
        img: '/img/washer/42.jpg',
        desc: 'Габарити: 85*60*40см, Загрузка: 4.5кг, Швидкість обертання: 800об/хв, Країна: Італія',
        category: 'washer',
        price: '4800',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.43,
        title: 'Indesit',
        img: '/img/washer/43.jpg',
        desc: 'Модель: WIUN100, Габарити: 85*60*30см, Загрузка: 3.5кг, Швидкість обертання: 800об/хв, Управління: Механічне, Країна: Італія',
        category: 'washer',
        price: '5600',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.44,
        title: 'Miele',
        img: '/img/washer/44.jpg',
        desc: 'Модель: Softtron8c w477, Габарити: 85*60*60см, Загрузка: 7кг, Швидкість обертання: 1600об/хв, Країна: Німеччина',
        category: 'washer',
        price: '7999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 6.45,
        title: 'Whirlpool',
        img: '/img/washer/45.jpg',
        desc: 'Габарити: 85*60*40см, Загрузка: 4.5кг, Швидкість обертання: 1000об/хв, Швидке прання: Так, Країна: Італія',
        category: 'washer',
        price: '5800',
        hot: false,
        url: './NoInfo',
      },

      {
        id: 7.1,
        title: 'AEG',
        img: '/img/verticale/1.jpg',
        desc: 'Модель: L8TE84565, Габарити: 85*40*60см, Загрузка: 1-6кг, Швидкість обертання: 1400об/хв, Країна: Швеція, Двигун: Інвенторний, Енергозбереження: A+++, Автоваги: Так',
        category: 'verticale',
        price: '13900',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 7.2,
        title: 'Bosch Classixx 6',
        img: '/img/verticale/2.jpg',
        desc: 'Габарити: 85*40*60см, Загрузка: 5.5кг, Швидкість обертання: 1000об/хв, Країна: Німеччина,  Енергозбереження: A+',
        category: 'verticale',
        price: '7999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 7.3,
        title: 'Bosch',
        img: '/img/verticale/3.jpg',
        desc: 'Габарити: 85*40*60см, Загрузка: 5кг, Швидкість обертання: 1000об/хв, Країна: Німеччина',
        category: 'verticale',
        price: '7700',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 7.4,
        title: 'Electrolux ',
        img: '/img/verticale/4.jpg',
        desc: 'Габарити: 85*40*60см, Загрузка: 6кг, Швидкість обертання: 1300об/хв, Двигун: Інвертор, Енергозбереження: А+++ -35%, Країна: Німеччина',
        category: 'verticale',
        price: '9500',
        hot: false
      },
      {
        id: 7.5,
        title: 'Bosch',
        img: '/img/verticale/5.jpg',
        desc: 'Габарити: 85*40*60см, Загрузка: 1-5кг, Швидкість обертання: 1100об/хв, Країна: Німеччина',
        category: 'verticale',
        price: '7500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 7.6,
        title: 'Zanussi',
        img: '/img/verticale/6.jpg',
        desc: 'Габарити: 85*40*60см, Загрузка: 5.5кг, Швидкість обертання: 1000об/хв, Країна: Швеція, Енергозбереження: А+',
        category: 'verticale',
        price: '5999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 7.7,
        title: 'AEG',
        img: '/img/verticale/7.jpg',
        desc: 'Габарити: 85*40*60см, Модель: L71269tl, Загрузка: 6кг, Швидкість обертання: 1400об/хв, Країна: Швеція, А+++',
        category: 'verticale',
        price: '11000',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 7.8,
        title: 'Electrolux',
        img: '/img/verticale/8.jpg',
        desc: 'Габарити: 85*40*60см, Загрузка: 6кг, Швидкість обертання: 1300об/хв, Двигун: Інвертор, Енергозбереження: А+++ -35%, Країна: Італія',
        category: 'verticale',
        price: '9500',
        hot: false,
        url: './NoInfo',
      },

      {
        id: 8.1,
        title: 'Constructa',
        img: '/img/dryer/1.jpg',
        desc: 'Габарити: 85*60*60см, Модель: CWK6B200, Загрузка: 7кг, Тип: Кондисаційна, Енергозбереження: А++, Країна: Німеччина',
        category: 'dryer',
        price: '12499',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 8.2,
        title: 'Bosch Home Pro',
        img: '/img/dryer/2.jpg',
        desc: 'Габарити: 85*60*60см, Модель: WTY87701, Загрузка: 9кг, Двигун: Інвентор, Енергозбереження: А+++ -35%, Тип: Компресорна з тепловим насосом, Країна: Німеччина',
        category: 'dryer',
        price: '16000',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 8.3,
        title: 'Bosch EXCLUSIV 8',
        img: '/img/dryer/3.jpg',
        desc: 'Модель: WTW875E27/07, Габарити: 85*60*60см, Загрузка: 9кг, Двигун: Інвентор, Енергозбереження: А+++ -35%, Тип: Компресорна з тепловим насосом, Країна: Німеччина',
        category: 'dryer',
        price: '16000',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 8.4,
        title: 'Samsung',
        img: '/img/dryer/4.jpg',
        desc: 'Габарити: 85*60*60см, Модель: DV80F5E5HGW, Загрузка: 8кг, Двигун: Інвентор, Тип: Компресорна з тепловим насосом, А+++',
        category: 'dryer',
        price: '13000',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 8.5,
        title: 'Electrolux',
        img: '/img/dryer/5.jpg',
        desc: 'Габарити: 85*60*60см, Модель: TE1120, Загрузка: 8кг, Країна: Італія, Тип: Компресорна,  А++',
        category: 'dryer',
        price: '14500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 8.6,
        title: 'Siemens IQ700',
        img: '/img/dryer/6.jpg',
        desc: 'Габарити: 85*60*60см, Модель: WT44W2E1/01, Загрузка: 8кг, Країна: Німеччина, Тип: Компресорна, А++',
        category: 'dryer',
        price: '13500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 8.7,
        title: 'Siemens IQ300',
        img: '/img/dryer/7.jpg',
        desc: 'Габарити: 85*60*60см, Модель: WT45W2G1/08, Загрузка: error, Країна: Німеччина, Тип: Компресорна, А++',
        category: 'dryer',
        price: '12999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 8.8,
        title: 'Zanussi',
        img: '/img/dryer/8.jpg',
        desc: 'Габарити: 85*60*60см, Модель: Lindo100, Загрузка: 7кг, Тип: Вентиляційний, Країна: Наша Ненька Батьківщина:)',
        category: 'dryer',
        price: '7500',
        hot: false,
        url: './NoInfo',
      },
      
      {
        id: 9.1,
        title: 'Siemens iQ700',
        img: '/img/2in1/1.jpg',
        desc: 'Габарити: 85*60*60см, Модель: WD14H540/06, Загрузка: 7/4кг, Двигун: Інвертор, Двигун: Інвентор, Енергозбереження: А+++ -35%, Країна: Німеччина',
        category: '2in1',
        price: '15500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 9.2,
        title: 'Candy',
        img: '/img/2in1/2.jpg',
        desc: 'Габарити: 85*60*70см, Загрузка: 14/9кг, Двигун: Інвенторний, Управління: Сенсор Wi-fi',
        category: '2in1',
        price: '19500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 9.3,
        title: 'Hoover',
        img: '/img/2in1/3.jpg',
        desc: 'Габарити: 85*60*70см, Модель: Schaum Aktiv, Загрузка: 14/9кг, Швидкість: 1600об/хв, Двигун: Інвенторний, Управління: Сенсор Wi-fi, Країна: Франція',
        category: '2in1',
        price: '19999',
        hot: true,
        url: './Hoover1984',
      },
      {
        id: 9.4,
        title: 'Siemens IQ500',
        img: '/img/2in1/4.jpg',
        desc: 'Габарити: 85*60*60см, Модель: WD15G443, Загрузка: 7/4кг, Двигун: Інвенторний, Країна: Німеччина, А+++',
        category: '2in1',
        price: '17200',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 9.5,
        title: 'Bosch Serie 6',
        img: '/img/2in1/5.jpg',
        desc: 'Габарити: 85*60*60см, Модель: WNG24440, Загрузка: 9/6кг, Управління: Сенсорне, Енергозбереження: А+++, Швидкість: 1400об/хв, Двигун: Інвенторний, Країна: Німеччина',
        category: '2in1',
        price: '20999',
        hot: false,
        url: './Bosch2279',
      },
      {
        id: 9.6,
        title: 'Samsung',
        img: '/img/2in1/6.jpg',
        desc: 'Модель: WD80J6410AW/EF, Габарити: 85*60*60см, Загрузка: 8/6кг, Швидкість: 1400 об/хв, Двигун: Інвентор, Енергозбереження: А+++ -35%, Країна: Корея',
        category: '2in1',
        price: '15500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 9.7,
        title: 'Bosch Serie 6',
        img: '/img/2in1/7.jpg',
        desc: 'Габарити: 85*60*60см, Модель: WDU28590, Загрузка: 10/6кг, Управління: Сенсорне і WiFi, Енергозбереження: А+++, Швидкість: 1400об/хв, Двигун: Інвенторний, Країна: Німеччина',
        category: '2in1',
        price: '26500',
        hot: true,
        url: './Bosch2278',
      },
      {
        id: 9.8,
        title: 'AEG',
        img: '/img/2in1/8.jpg',
        desc: 'Габарити: 85*60*60см, Модель: L99699HWD, Загрузка: 9/6кг, Швидкість: 1400 об/хв, Двигун: Інвентор, Енергозбереження: А+++ -35%, Країна: Словаччина',
        category: '2in1',
        price: '14900',
        hot: false,
        url: './NoInfo',
      },
      
      {
        id: 10.1,
        title: 'Kaiser',
        img: '/img/dishwasher/1.jpg',
        desc: 'Габарити: 83*45*60см, ВСТРОЄННА, Проекція: Так, Аквастоп: Так, Країна: Німеччина, А+++',
        category: 'dishwasher',
        price: '9500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 10.2,
        title: 'Bosch',
        img: '/img/dishwasher/2.jpg',
        desc: 'Габарити: 83*45*60см, ВСТРОЄННА, Проекція: Так, Сушка: Так, Аквастоп: Так, Країна: Німеччина, А+++',
        category: 'dishwasher',
        price: '9000',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 10.3,
        title: 'Beko',
        img: '/img/dishwasher/3.jpg',
        desc: 'Габарити: 83*45*57см, ВСТРОЄННА, Двигун: ProSmartInverter, Проекція: Так, Сушка: Так, Аквастоп: Так, Країна: Туреччина, А+++',
        category: 'dishwasher',
        price: '12000',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 10.4,
        title: 'Gorenje',
        img: '/img/dishwasher/4.jpg',
        desc: 'Габарити: 83*45*59см, ВСТРОЄННА, Проекція: Так, Сушка: Так, Аквастоп: Так, Країна: Словенія',
        category: 'dishwasher',
        price: '7800',
        hot: false,
        url: './NoInfo',
      },
      
      {
        id: 11.1,
        title: 'Hanseatic',
        img: '/img/microwave/1.jpg',
        desc: 'Обєм: 30л, Країна: Німеччина, Управління: Електронно механічне, Гриль: Так, Конвекція: Так, Розморозка: Так, Країна: Німеччина',
        category: 'microwave',
        price: '3500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 11.2,
        title: 'Severin',
        img: '/img/microwave/2.jpg',
        desc: 'Обєм: 25л, Країна: Німеччина, Управління: Механічне, Гриль: Так',
        category: 'microwave',
        price: '2900',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 11.3,
        title: 'CNTronk',
        img: '/img/microwave/3.jpg',
        desc: 'Обєм: 25л, Країна: Німеччина, Управління: Механічне',
        category: 'microwave',
        price: '3000',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 11.4,
        title: 'Samsung',
        img: '/img/microwave/4.jpg',
        desc: 'Обєм: 30л, Країна: Корея, Управління: Механічне',
        category: 'microwave',
        price: '2900',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 11.5,
        title: 'Severin',
        img: '/img/microwave/5.jpg',
        desc: 'Обєм: 30л, Країна: Німеччина, Управління: Механічне, Гриль: Так',
        category: 'microwave',
        price: '3000',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 11.6,
        title: 'Exqusit',
        img: '/img/microwave/6.jpg',
        desc: 'Обєм: 25л, Країна: Німеччина, Управління: Механічне',
        category: 'microwave',
        price: '3000',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 11.7,
        title: 'Coso',
        img: '/img/microwave/7.jpg',
        desc: 'Обєм: 35л, Модель: MCDG25, Країна: Німеччина, Управління: Механічне, Гриль: Так',
        category: 'microwave',
        price: '3900',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 11.8,
        title: 'Bosch',
        img: '/img/microwave/8.jpg',
        desc: 'Обєм: 20л, Країна: Німеччина, Управління: Механічне',
        category: 'microwave',
        price: '3500',
        hot: false,
        url: './NoInfo',
      },

      {
        id: 12.1,
        title: 'Gorenje',
        img: '/img/oven/1.jpg',
        desc: 'Габарити: 59.5*59.5*59.5см, Країна: Словенія, Управління: Сенсорний екран, Конвекція: Так, Гриль: Так, Протвінь: Так, А+++',
        category: 'oven',
        price: '11500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 12.2,
        title: 'BLAUPUNKT',
        img: '/img/oven/2.jpg',
        desc: 'Габарити: 59.5*59.5*56см, Країна: Німеччина, Управління: Сенсорний екран, Мікрохвильовка: Так, Конвекція: Так, Гриль: Так, Протвінь та решітка: Так, А+++',
        category: 'oven',
        price: '9999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 12.3,
        title: 'Amica',
        img: '/img/oven/3.jpg',
        desc: 'Габарити: 59*59*57см, Країна: Польща, Укправління: Механічно електронне, Конвекція: Так, Гриль: Так, Протвінь та решітка: Так',
        category: 'oven',
        price: '6100',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 12.4,
        title: 'Mastercook',
        img: '/img/oven/4.jpg',
        desc: 'Габарити: 59.5*59.5*59.5см, Країна: Польща, Укправління: Електронне, Конвекція: Так, Розморозка: Так, Протвінь та решітка: Так',
        category: 'oven',
        price: '7100',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 12.5,
        title: 'Bosch',
        img: '/img/oven/5.jpg',
        desc: 'Габарити: 60*60*56см, Країна: Німеччина, Укправління: Електронне, Конвекція: Так, Гриль: Так, Табло: Так',
        category: 'oven',
        price: '9999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 12.6,
        title: 'Miele',
        img: '/img/oven/6.jpg',
        desc: 'Габарити: 45*60*56см, Країна: Німеччина, Матеріал: Нержавійка, Управління: Сенсорне, Конвекція: Так, СВЧ: Так, Рецепти: Так, Память: Так',
        category: 'oven',
        price: '15800',
        hot: false,
        url: './NoInfo',
      },

      {
        id: 13.1,
        title: 'Скоро!',
        img: '/img/stove/1.png',
        desc: 'Все роспродано! Ждемо нові надходження ;)',
        category: 'stove',
        price: '0',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 13.2,
        title: 'Скоро!',
        img: '/img/stove/1.png',
        desc: 'Все роспродано! Ждемо нові надходження ;)',
        category: 'stove',
        price: '0',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 13.3,
        title: 'Скоро!',
        img: '/img/stove/1.png',
        desc: 'Все роспродано! Ждемо нові надходження ;)',
        category: 'stove',
        price: '0',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 13.4,
        title: 'Скоро!',
        img: '/img/stove/1.png',
        desc: 'Все роспродано! Ждемо нові надходження ;)',
        category: 'stove',
        price: '0',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 13.5,
        title: 'Скоро!',
        img: '/img/stove/1.png',
        desc: 'Все роспродано! Ждемо нові надходження ;)',
        category: 'stove',
        price: '0',
        hot: false,
        url: './NoInfo',
      },
      
      {
        id: 14.1,
        title: 'Neff ',
        img: '/img/hob/1.jpg',
        desc: 'Габарити: 56*56см, Управління: Сенсорне, Країна: Німеччина',
        category: 'hob',
        price: '6999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 14.2,
        title: 'Еlectrolux',
        img: '/img/hob/2.jpg',
        desc: 'Габарити: 74*78см, Управління: Сенсорне, Країна: Італія',
        category: 'hob',
        price: '9999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 14.3,
        title: 'Silver',
        img: '/img/hob/3.jpg',
        desc: 'Габарити: 74*78см, Управління: Сенсорне, Країна: Німеччина',
        category: 'hob',
        price: '7999',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 14.4,
        title: 'Bosch',
        img: '/img/hob/4.jpg',
        desc: 'Габарити: 54*55см, ВСТРОЄННА, Управління: Сенсорне, Країна: Німеччина',
        category: 'hob',
        price: '7900',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 14.5,
        title: 'Respekta',
        img: '/img/hob/5.jpg',
        desc: 'Габарити: error, Управління: Сенсорне, Країна: Італія',
        category: 'hob',
        price: '4300',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 14.6,
        title: 'Bosch',
        img: '/img/hob/6.jpg',
        desc: 'Габарити: 73*48см, Управління: Сенсорне, Країна: Німеччина',
        category: 'hob',
        price: '9500',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 14.7,
        title: 'Neff',
        img: '/img/hob/7.jpg',
        desc: 'Габарити: 56*56см, ВБУДОВАНА, Управління: Сенсорне, Країна: Німеччина',
        category: 'hob',
        price: '6200',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 14.8,
        title: 'Ikea',
        img: '/img/hob/8.jpg',
        desc: 'Габарити: 57*50см, Управління: Механічне, Країна: Італія',
        category: 'hob',
        price: '4000',
        hot: false,
        url: './NoInfo',
      },
      {
        id: 14.9,
        title: 'Bauknecht',
        img: '/img/hob/9.jpg',
        desc: 'Габарити: 57*50см, Управління: Сенсорне, Країна: Італія',
        category: 'hob',
        price: '3500',
        hot: false,
        url: './NoInfo',
      }
  ];
  
export default items;
  