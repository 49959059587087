import React from 'react'

export default function Footer() {
  return (
    <footer className='footer'>
        <div className='container'>
          <div className="footer-inner">
            <div className="footer-item">
                <h3>Контакти</h3>
                <p>Адреса: М.Київ, Бульвар Вацлава Гавела 31</p>
                <p>Графік: пн.-пт. 10:00-19:00; сб.-нд. 11:00-17:00</p>
                <p>Email: eurostocknt@gmail.com</p>
                <p>Телефон: 093 645 0210</p>
            </div>
            <div className="footer-item">
                <h3>Швидкі посилання</h3>
                <ul>
                  <li><a href="Home">Головна</a></li>
                  <li><a href="Catalog">Каталог</a></li>
                  <li><a href="Service">Сервіс</a></li>
                  <li><a href="Contacts">Контакти</a></li>
                  <li><a href="About">Про нас</a></li>
                </ul>
            </div>
          </div>
        </div>
      </footer>
  )
}