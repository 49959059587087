import React, { useState } from 'react';
import { FaCheckCircle, FaTools, FaStar, FaDollarSign, FaThumbsUp } from 'react-icons/fa';
import TabOpen from '../TabOpen';
import { Helmet } from 'react-helmet';

const ProductImageSection = () => {
  const [mainImage, setMainImage] = useState('/img/fridge/top/Haier2206/Haier2206-1.jpg');
  const [isFullscreen, setIsFullscreen] = useState(false);

  const images = [
    '/img/fridge/top/Haier2206/Haier2206-1.jpg',
    '/img/fridge/top/Haier2206/Haier2206-2.jpg',
    '/img/fridge/top/Haier2206/Haier2206-3.jpg',
    '/img/fridge/top/Haier2206/Haier2206-4.jpg',
    '/img/fridge/top/Haier2206/Haier2206-5.jpg',
  ];

  const handleImageClick = (image) => {
    if (!isFullscreen) {
      setMainImage(image);
    }
  };

  const handleFullscreenClick = () => {
    setIsFullscreen(true);
  };

  const handleFullscreenClose = () => {
    setIsFullscreen(false);
  };

  return (
    <div className="product-image-section">
      <div className="main-image-wrapper">
        <div
          className={`main-image ${isFullscreen ? 'fullscreen' : ''}`}
          onClick={isFullscreen ? handleFullscreenClose : handleFullscreenClick}
        >
          <img src={mainImage} alt="Product" />
        </div>
      </div>
      <div className="thumbnail-gallery">
        {images.map((image, index) => (
          <div key={index} className="thumbnail" onClick={() => handleImageClick(image)}>
            <img src={image} alt={`Thumbnail ${index}`} />
          </div>
        ))}
      </div>
      {isFullscreen && (
        <div className="fullscreen-overlay" onClick={handleFullscreenClose}></div>
      )}
    </div>
  );
};

const ReviewSection = () => {
    const [review, setReview] = useState('');
    const [submissionStatus, setSubmissionStatus] = useState('');
  
    const handleChange = (e) => {
      setReview(e.target.value);
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      try {
        const response = await fetch('https://formspree.io/f/meqbbvej', { 
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ review }),
        });
  
        if (response.ok) {
          setSubmissionStatus('Ваш відгук успішно надіслано!');
          setReview('');
        } else {
          setSubmissionStatus('Помилка при надсиланні відгуку. Спробуйте ще раз.');
        }
      } catch (error) {
        setSubmissionStatus('Помилка при надсиланні відгуку. Спробуйте ще раз.');
      }
    };
  
    return (
      <div className="review-section-complex">
        <h2 className="review-section-title">Залиште свій відгук</h2>
        <form onSubmit={handleSubmit} className="review-form-complex">
          <textarea
            value={review}
            onChange={handleChange}
            placeholder="Ваш відгук..."
            rows="6"
            className="review-textarea-complex"
          />
          <button type="submit" className="review-submit-button">Надіслати</button>
        </form>
        {submissionStatus && <p className="review-submission-status">{submissionStatus}</p>}
      </div>
    );
  };

  const QuestionsSection = () => {
    const [question, setQuestion] = useState('');
    const [submissionStatus, setSubmissionStatus] = useState('');
  
    const handleChange = (e) => {
      setQuestion(e.target.value);
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      try {
        const response = await fetch('https://formspree.io/f/meqbbvej', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ question }),
        });
  
        if (response.ok) {
          setSubmissionStatus('Ваше питання успішно надіслано!');
          setQuestion('');
        } else{
          setSubmissionStatus('Помилка при надсиланні питання. Спробуйте ще раз.');
        }
      } catch (error) {
        setSubmissionStatus('Помилка при надсиланні питання. Спробуйте ще раз.');
      }
    };
  
    return (
      <div className="question-section-complex">
        <h2 className="question-section-title">Задайте питання</h2>
        <form onSubmit={handleSubmit} className="question-form-complex">
          <textarea
            value={question}
            onChange={handleChange}
            placeholder="Ваше питання..."
            rows="6"
            className="question-textarea-complex"
          />
          <button type="submit" className="question-submit-button">Надіслати</button>
        </form>
        {submissionStatus && <p className="question-submission-status">{submissionStatus}</p>}
      </div>
    );
  };

  const ContactForm = () => {
    const [phone, setPhone] = useState('');
    const [submissionStatus, setSubmissionStatus] = useState('');
  
    const handleChange = (e) => {
      setPhone(e.target.value);
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      try {
        const response = await fetch('https://formspree.io/f/meqbbvej', { 
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ phone }),
        });
  
        if (response.ok) {
          setSubmissionStatus('Ваше повідомлення успішно надіслано!');
          setPhone('');
        } else {
          setSubmissionStatus('Помилка при надсиланні повідомлення. Спробуйте ще раз.');
        }
      } catch (error) {
        setSubmissionStatus('Помилка при надсиланні повідомлення. Спробуйте ще раз.');
      }
    };
  
    return (
      <div className="contact-form">
        <input
          type="tel"
          value={phone}
          onChange={handleChange}
          className="contact-input"
          placeholder="Ваш номер телефону"
        />
        <button onClick={handleSubmit} className="contact-submit-button">Звернутись</button>
        {submissionStatus && <p className="contact-submission-status">{submissionStatus}</p>}
      </div>
    );
  };

function Haier2206() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const details = [
    {
      title: 'Основні характеристики',
      important: true,
      items: [
        { question: 'Спосіб установки', answer: 'Окремостоячий' },
        { question: 'Габарити (ШхВхГ)', answer: '70x190x67 см' },
        { question: 'Об\'єм холодильника', answer: '307 л' },
        { question: 'Об\'єм морозильної камери', answer: '114 л' },
        { question: 'Клас енергоспоживання', answer: 'A++' },
        { question: 'Тип охолодження', answer: 'Total No Frost' },
        { question: 'Рівень шуму', answer: '38 дБ' },
        { question: 'Тип управління', answer: 'Сенсорний' },
        { question: 'Кількість камер', answer: '3' },
      ],
    },
    {
      title: 'Функції та оснащення',
      important: false,
      items: [
        { question: "Multi Air Flow", answer: "Так" },
        { question: "Зона свіжості", answer: "Так" },
        { question: "Функція швидкого охолодження", answer: "Так" },
        { question: "Функція швидкого заморожування", answer: "Так" },
        { question: "Захист від бактерій ABT", answer: "Так" },
        { question: "Енергозберігаючий режим", answer: "Так" },
        { question: "Захист від дітей", answer: "Так" },
      ],
    },
    {
      title: 'Застосовувані технології',
      important: false,
      items: [
        { technology: "Total No Frost", available: "Так" },
        { technology: "Multi Air Flow", available: "Так" },
        { technology: "ABT (Anti-Bacterial Technology)", available: "Так" },
        { technology: "My Zone", available: "Так" },
        { technology: "Super Freeze", available: "Так" },
        { technology: "Holiday Mode", available: "Так" },
      ],
    },
  ];
  

  return (
    <div className='container'>
      <Helmet>
        <meta name="description" content="Холодильник Haier A3FE742-CMJ. 0936450210" />
        <meta name="author" content="EUROSTOCK" />
        <meta name="keywords" content='Продається холодильник Haier A3FE742-CMJ, Холодильник Haier A3FE742-CMJ, Haier A3FE742-CMJ, Haier A3FE742-CMJ EUROSTOCK, Бу холодильники, Бу пралки, Холодильники з Європи, Пралки з Європи, Бу холодильники Київ, Бу пралки Київ, Бу техніка Київ, Бу техніка, Бу техника, Бу техника Киев, Техника из Европы Киев, Техника из Европы'/>
        <meta property="og:title" content="Холодильник Haier A3FE742-CMJ" />
        <meta property="og:description" content="Продається холодильник Haier A3FE742-CMJ. 0936450210" />
        <meta property="og:image" content="%PUBLIC_URL%/img/fridge/top/Haier2206/Haier2206-1.jpg" />
        <meta property="og:url" content="http://euro-stock.com.ua/catalog" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div>
        <div className="scroll-section-container">
          <button onClick={() => scrollToSection('section1')}>Опис</button>
          <button onClick={() => scrollToSection('section2')}>Характеристики</button>
          <button onClick={() => scrollToSection('section3')}>Відгуки 0</button>
          <button onClick={() => scrollToSection('section4')}>Питання 0</button>
          <button onClick={() => scrollToSection('section5')}>Гарантія на товар</button>
          <button onClick={() => scrollToSection('section6')}>Умови Оплати і Доставки</button>
        </div>

        <section id="section1">
          <div className='section1-part1'>
            <ProductImageSection />
          </div>
          <div className='section1-part2'>
            <div className="product-description-container">
              <header className="product-title-header">
                <h1 className="product-title-text">Haier</h1>
                <h1 className="product-model-text">A3FE742-CMJ</h1>
              </header>
              <div className="product-info-container">
                <div className="info-box-item">
                  <span className="info-box-text">Номер: #2206</span>
                </div>
                <div className="info-box-item">
                  <span className="info-box-text">Кількість: 1</span>
                </div>
                <div className="info-box-item">
                  <span className="info-box-text">В наявності</span>
                </div>
              </div>
              <div className="product-price-header">
                <h3 className="price-title">29 500грн</h3>
              </div>
              <a href='https://t.me/eurostock_nt' className="purchase-button">Купити</a>
              <div className="purchase-reasons-container">
                <h3 className="reasons-title">Причини купити цей товар</h3>
                <div className="reasons-list">
                    <div className="reason-item">
                        <FaCheckCircle className="icon" style={{ color: '#007bff' }} />
                        Вища якість
                    </div>
                    <div className="reason-item">
                        <FaTools className="icon" style={{ color: '#6c757d' }} />
                        Техніка протестована
                    </div>
                    <div className="reason-item">
                        <FaStar className="icon" style={{ color: '#28a745' }} />
                        Ексклюзивна модель
                    </div>
                    <div className="reason-item">
                        <FaDollarSign className="icon" style={{ color: '#dc3545' }} />
                        Найнижча ціна
                    </div>
                    <div className="reason-item">
                        <FaThumbsUp className="icon" style={{ color: '#ffc107' }} />
                        Відмінний стан
                    </div>
                </div>
              </div>
              <div className="contact-section">
                <div className="horizontal-line"></div>
                <div className="horizontal-line"></div>
                <h4 className="contact-question">Є питання?</h4>
                <ContactForm />
              </div>
            </div>
          </div>
          <div className='section1-part3'>
            <div className="complex-payment-delivery-container">
              <div className="complex-section-block">
                <h3 className="complex-section-title">Оплата</h3>
                <ul className="complex-section-list">
                  <li className="complex-section-list-item">Готівка</li>
                  <li className="complex-section-list-item">Банківський перерахунок</li>
                </ul>
              </div>
              <div className="complex-section-block">
                <h3 className="complex-section-title">Доставка</h3>
                <ul className="complex-section-list">
                  <li className="complex-section-list-item">Доставка по Україні Новою Поштою</li>
                  <li className="complex-section-list-item">Самовивіз товару зі складу</li>
                  <li className="complex-section-list-item">Наша доставка по Києву і області</li>
                </ul>
              </div>
              <div className="complex-section-block">
                <h3 className="complex-section-title">Гарантія</h3>
                <ul className="complex-section-list">
                  <li className="complex-section-list-item">Від 1 до 6 місяців</li>
                  <li className="complex-section-list-item">Ми не включаємо у вартість товару гарантію на 12 і більше місяців, так як це зробило б товар суттєво дорожче.</li>
                </ul>
              </div>
              <div className="complex-section-block">
                <h3 className="complex-section-title">Демонстрація товару</h3>
                <p className="complex-contact-description">Залиште свій номер телефону, і ми надішлемо Вам детальні фото і відео товару на Viber.</p>
              </div>
            </div>
          </div>
        </section>
        <section id="section2">
        <div className="product-details-section-xyz">
            {details.map((detail, index) => (
              <div key={index} className={`product-detail-section ${detail.important ? 'important-xyz' : 'less-important-xyz'}`}>
                <h2 className="product-detail-section-title-xyz">{detail.title}</h2>
                {detail.items.map((item, index) => (
                  <div key={index} className="product-detail-item-xyz">
                    <span className="product-question-xyz">{item.question || item.technology}</span>
                    <span className="product-answer-xyz">{item.answer || item.available}</span>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </section>
        <section id="section3">
            <ReviewSection />
        </section>
        <section id="section4">
            <QuestionsSection />
        </section>
        <section id="section5">
            <TabOpen/>
        </section>
        <section id="section6">
            <h1 className="titles">Доставка</h1>
            <div className='Service-2'>
                <p>Ви можете забрати техніку самовивозом, або скористатись однією з цих послуг доставки:</p>
                <div className='Service-2-1'>
                <div className="Service-2-1-1">
                    <h2>Адресна доставка</h2>
                    <p>Швидка й зручна доставка по Києву та області, з можливістю <strong>ПЛАТНОЇ</strong> послуги заносу, від нашої фірми.</p>
                </div>
                <div className="Service-2-1-2">
                    <h2>Нова пошта</h2>
                    <p>Доставка по всій Україні (окрім тимчасово окупованих територій).</p>
                </div>
                </div>
                <p className="Service-alert">Розрахунок вартості нашої адресної доставки по столиці й області здійснює менеджер!</p>
            </div>

            <h1 className="titles">Оплата</h1>
            <div className='Service-3'>
                <p>Ви можете скористатись найбільш зручним особисто для Вас методом оплати:</p>
                <div className='Service-3-1'>
                <div className="Service-3-1-1">
                    <img src={process.env.PUBLIC_URL + '/img/Service-3-1.jpg'} alt="Service-3-1"/>
                    <h2>Банківський перерахунок</h2>
                    <p>Зручна й швидка оплата через ваш онлайн-банк.</p>
                </div>
                <div className='Service-3-1-2'>
                    <img src={process.env.PUBLIC_URL + '/img/Service-3-2.jpg'} alt="Service-3-2"/>
                    <h2>Готівка</h2>
                    <p>Оплата національною валютою в нашому магазині чи при отриманні товару.</p>
                </div>
                </div>
                <p className="Service-alert">В підтвердження оплати ми видаємо Вам товарний чек і гарантійний талон!</p>
            </div>
        </section>
      </div>
    </div>
  );
}

export default Haier2206;